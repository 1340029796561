import React, { useContext, useEffect, useRef, useState } from 'react'
import avatar from "../../../assets/avatar.svg"
import { useLocation, useNavigate } from 'react-router-dom'
import axios from 'axios'
import config from '../../../config'
import { auth } from '../../../Context/AuthStore'
import Placeholder from '../../../Components/Used/Placeholder'
import CSVMake from '../../../CSVMake'
import Validator from '../../../Validator'
import Swal from 'sweetalert2'
import SearchBox from '../../../Components/Dashboard/SearchBox/SearchBox'
import useQueryParams from '../../../Hooks/useQueryParams'

export default function Students() {

    const navigation = useNavigate();
    const location = useLocation()
    const query = useQueryParams()
    const { userAuth } = useContext(auth)
    const [studentsData, setStudentsData] = useState(false)
    const [nextPage, setNextPage] = useState(false)
    const [currentPage, setCurrentPage] = useState(1)
    const [data, setData] = useState(false);
    const [resultBySerach, setresultBySerach] = useState(false)
    const [studentCount, setstudentCount] = useState(0)
    const [pageCount, setpageCount] = useState(1)
    const [pages, setpages] = useState([])
    const [isLoading, setisLoading] = useState(false)

    const [openFilter, setopenFilter] = useState(false)

    const [preparing, setpreparing] = useState(false)
    const [courseList, setcourseList] = useState(false)

    const [selectedCourse, setselectedCourse] = useState({
        course: null
    })

    const updateSelectedCourse = (e) => {
        let data = { ...selectedCourse };
        data[e.target.name] = e.target.value;
        setselectedCourse(data)
    }

    const handleOpenFilter = () => {
        openFilter ? setopenFilter(false) : setopenFilter(true)
    }

    const changePage = (pageNo) => {
        let link = '/dashboard/students';
        let all_queries = {...query , page:pageNo}

        if (Object.keys(all_queries).length > 0) {
            let query_keys = Object.keys(all_queries)
            query_keys.forEach((key, index) => {
                if (index == 0) {
                    link += `?${key}=${all_queries[key]}`
                } else {
                    link += `&${key}=${all_queries[key]}`
                }
            });
        }

        navigation(link)

    }

    const getData = () => {
        axios.get(`${config.basURL}student/student-extra`, {
            headers: {
                'Authorization': config.ApiKey
            }
        }).then((res) => {
            // console.log(res.data)
            setData(res.data);
        }).catch((err) => {
            console.log(err)
        })
    }

    const getStudentsData = () => {
        setStudentsData(false)

        let link = `${config.basURL}dashboard/students-list`

        if (Object.keys(query).length > 0) {
            let query_keys = Object.keys(query)
            query_keys.forEach((key, index) => {
                if (index == 0) {
                    link += `?${key}=${query[key]}`
                } else {
                    link += `&${key}=${query[key]}`
                }
            });
        }

        axios.get(link, {
            headers: {
                'Authorization': config.ApiKey,
                'auth': `Bearer ${userAuth}`
            }
        }).then((res) => {
            let data = res.data.results
            let pagesCount = Math.ceil(res.data.count / 100)
            let paginationPages = [...pages]
            paginationPages = []

            for (let i = 1; i <= pagesCount; i++) {
                paginationPages.push('t')
            }
            setpages(paginationPages)
            setpageCount(pagesCount)
            setstudentCount(res.data.count)
            setStudentsData(data)
            res.data.next ? setNextPage(true) : setNextPage(false)
        }).catch((err) => {
            console.log(err)
        })
    }

    const deleteStudent = (username) => {
        Swal.fire({
            title: `هل انت متاكد من الحذف `,
            icon: "warning",
            showCancelButton: true,
            cancelButtonText: 'لا شكرا',
            showConfirmButton: true,
            confirmButtonText: 'حذف',
        }).then((willDelete) => {
            if (willDelete.isConfirmed) {
                axios.delete(`${config.basURL}dashboard/user-destroy/${username}`, {
                    headers: {
                        'Authorization': config.ApiKey,
                        'auth': `Bearer ${userAuth}`
                    }
                }).then(res => {
                    getStudentsData()
                    Swal.fire({
                        text: 'تم الحذف بنجاح',
                        icon: 'success'
                    })
                }).catch(err => {
                    console.log(err)
                    Swal.fire({
                        text: ' حدث خطاء برجاء المحاوله مره اخري ',
                        icon: 'error'
                    })
                })
            }
        })
    }

    const exportCsv = () => {
        let csv = new CSVMake;
        csv.setTableHeader({
            name: 'أسم الطالب',
            code: 'الكود',
            user: ['username', ' رقم الطالب'],
            parent_phone: 'رقم ولي الامر',
            year: ['name', 'السنه الدراسية'],
            place: ['name', 'المكان'],
            type_education: ['name', 'نوع التعليم'],
        }).setData(studentsData, {
            user: 'username',
            year: 'name',
            place: 'name',
            type_education: 'name'
        }).exportFile(` بيانات الطلاب - صفحه رقم ( ${currentPage} ) `)
    }

    const exportAll = () => {
        setpreparing(true)
        axios.get(`${config.basURL}center/export-student-course/${selectedCourse.course}`, {
            headers: {
                'Authorization': config.ApiKey,
                'auth': `Bearer ${userAuth}`
            }
        }).then(res => {
            setpreparing(false)
            console.log(res.data)
            // return;
            Swal.fire({
                text: 'سيتم التحميل الان',
                icon: 'success'
            })
            let csv = new CSVMake;
            if (res.data.length == 0) {
                Swal.fire({
                    text: ' لا يوجد مشاهدات في هذا الدرس  ',
                    icon: "warning"
                })
            } else {
                csv.setTableHeader({
                    student_name: 'أسم الطالب',
                    student__parent_phone: 'رقم ولي الامر',
                    student__user__username: ' رقم هاتف الطالب ',

                }).setData(res.data).exportFile(`  بيانات الطلاب في كورس ${courseList.filter(course => course.id == selectedCourse.course)[0].name}`)
            }

        }).catch(err => {
            setpreparing(false)
            console.log(err)
        })
    }

    const getCourseList = () => {
        axios.get(`${config.basURL}center/center-course-list`, {
            headers: {
                'Authorization': config.ApiKey,
                'auth': `Bearer ${userAuth}`,

            }
        }).then((res) => {
            setcourseList(res.data)
        }).catch((err) => {
            console.log(err)
        })
    }

    useEffect(() => {
        getStudentsData()
        if(query.page){
            setCurrentPage(query.page)
        }
    }, [location.search])

    useEffect(() => {
        getData()
        getCourseList()
    }, [userAuth])

    return <>
        <div className="container-fluid dashboard-students-section">
            <div className="head">
                <h6 className='m-0 p-0'>
                    الطلاب ( {studentCount} )
                </h6>
                <div className="btns">
                    <div className='d-flex flex-column align-items-center'>
                        <select name="course" id="" onChange={updateSelectedCourse}>
                            <option value={null}> اختار الكورس  </option>
                            {
                                courseList ? courseList.map(course => <option value={course.id} key={course.id}  > {course.name} </option>) : <option value={null}> جاري تحميل الكورسات </option>
                            }
                        </select>
                        {
                            selectedCourse.course !== null && <button className='export-file' onClick={exportAll}>
                                {
                                    preparing ? <>
                                        <i className="fa-solid fa-circle-notch fa-spin"></i>
                                        جاري تجهيز الفايل
                                    </> : <>
                                        <i className="fa-regular fa-file-excel"></i>
                                        جميع الطلاب
                                    </>
                                }
                            </button>
                        }
                    </div>
                    {
                        studentsData && <button className='export-file' onClick={exportCsv}>
                            <i className="fa-regular fa-file-excel"></i>
                            انشاء ملف Excel
                        </button>
                    }
                    <button className='filter' onClick={handleOpenFilter}>
                        <i className="ms-2 fa-solid fa-filter"></i>
                        فلتر
                    </button>
                    <button className='filter' onClick={() => navigation('/dashboard/students')}>
                        <i className="ms-2 fa-solid fa-filter-circle-xmark"></i>
                        الغاء الفلتر
                    </button>
                </div>
            </div>
            <table className='students-info student-table'>
                <thead>
                    <tr>
                        <th> التسلسل </th>
                        <th>
                            أسم الطالب
                        </th>
                        <th>
                            كود الطالب
                        </th>
                        <th>
                            كود السنتر
                        </th>
                        <th>
                            رقم الطالب
                        </th>
                        <th>
                            رقم ولي الامر
                        </th>
                        {/* <th>
                            السنه الدراسية
                        </th> */}
                        <th>
                            نوع التعليم
                        </th>
                        <th>
                            المكان
                        </th>
                        {/* <th>
                            الايميل
                        </th> */}
                        <th>
                            نوع الجهاز المسجل بيه
                        </th>
                        <th>

                        </th>
                    </tr>
                </thead>
                <tbody>
                    {
                        studentsData ? studentsData.length > 0 ? studentsData.map((student, index) => <tr key={index} >
                            <td className='image' onClick={() => navigation(`/dashboard/students/${student.id}`)}>
                                {/* <img src={avatar} alt="" className='w-100 h-100' /> */}
                                {index + 1}
                            </td>
                            <td className='text-center' onClick={() => navigation(`/dashboard/students/${student.id}`)}>
                                {student.name}
                            </td>
                            <td className='text-center' onClick={() => navigation(`/dashboard/students/${student.id}`)}>
                                {student?.code || 'لا يوجد'}
                            </td>
                            <td className='text-center' onClick={() => navigation(`/dashboard/students/${student.id}`)}>
                                {student?.center_code || 'لا يوجد'}
                            </td>
                            <td className='text-center' onClick={() => navigation(`/dashboard/students/${student.id}`)}>
                                {student.user.username}
                            </td>
                            <td className='text-center' onClick={() => navigation(`/dashboard/students/${student.id}`)}>
                                {student.parent_phone}
                            </td>
                            {/* <td className='text-center' onClick={() => navigation(`/dashboard/students/${student.id}`)}>
                                {student.year && student.year.name}
                            </td> */}
                            <td className='text-center' onClick={() => navigation(`/dashboard/students/${student.id}`)}>
                                {student.type_education && student.type_education.name}
                            </td>
                            <td className='text-center' onClick={() => navigation(`/dashboard/students/${student.id}`)}>
                                {student.by_code ? 'سنتر' : 'اونلاين'}
                            </td>
                            {/* <td className='text-center' onClick={() => navigation(`/dashboard/students/${student.id}`)}>
                                {student.user.email.length > 0 ? student.user.email : '--'}
                            </td> */}
                            <td onClick={() => navigation(`/dashboard/students/${student.id}`)}>
                                Phone
                            </td>
                            <td>
                                <button className='delete' onClick={() => deleteStudent(student.user.username)}>
                                    <i className="fa-regular fa-trash-can"></i>
                                </button>
                            </td>
                        </tr>) : <tr> <td colSpan='8' className='w-100 text-center'> لا يوجد طلاب </td> </tr> : <tr><td className='w-100' colSpan={9}> <Placeholder /> </td></tr>
                    }
                </tbody>
            </table>
            {/* <div className="pagination-btn">
                <button onClick={() => setCurrentPage((prev) => prev - 1)} className={`prev ${currentPage > 1 ? '' : 'disable'}`} disabled={currentPage > 1 ? false : true}> السابق </button>
                <button className={`next ${nextPage ? '' : 'disable'}`} disabled={nextPage ? false : true} onClick={() => setCurrentPage((prev) => prev + 1)}> التالي </button>
            </div> */}
            <div className="paegination-links">
                <ul className='w-100 py-2'>
                    {
                        pages.map((pa, index) => <li className={`${currentPage == (index + 1) ? 'active' : ''}`} onClick={() => changePage(index + 1)}> {index + 1} </li>)
                    }
                </ul>
                <p className='text-center m-0 py-1'> {currentPage} / {pageCount} </p>
            </div>
        </div>

        {
            <SearchBox feilds={[
                {
                    input: true,
                    name: 'name',
                    label: 'اسم الطالب'
                },
                {
                    input: true,
                    name: 'user__username',
                    label: ' رقم هاتف الطالب '
                },
                {
                    input: true,
                    name: 'code',
                    label: ' كود الطالب '
                },
                {
                    input: true,
                    name: 'parent_phone',
                    label: ' رقم ولي الامر '
                },
                {
                    select: true,
                    name: 'year__id',
                    label: ' السنه الدراسيه ',
                    options: data && [ {id:'', name:''} , ...data.years]
                },
                {
                    select: true,
                    name: 'by_code',
                    label: ' مكان التسجيل',
                    options: [{ id: '', name: '' }, { id: 0, name: 'اونلاين' }, { id: 1, name: 'سنتر' }]
                },
            ]} search={'/dashboard/students'} open={openFilter} switchOpen={handleOpenFilter} loading={isLoading} />
        }
    </>
}